import highligterStyles from '../../../extension/source/injection/index.css';
import docTextContentStyles from '../css/documentTextContent.css';

// We need the default header in case the header doesn't exist. E.g YouTube videos.
const getDefaultHeaderHTML = ({
  title,
  publishedDate,
  author,
}: { title: string; publishedDate: string; author: string }) => `
<div id="document-header">
  <div>
    <h1 lang="en">${title}</h1>
    <hr>
    <div class="document-header-metadata-row">
    <span class="document-header-author-content">
        <span class="document-header-author-text">${author}</span>
      </span>
      <span class="document-header-published-date">
        ${publishedDate}</a>
      </span>
    </div>
  </div>
</div>
`;

export const printDocumentContent = ({
  docTitle,
  docNotes,
  author,
  publishedDate,
}: { docTitle: string; docNotes?: string; publishedDate: string; author: string }) => {
  const docTextContent = document.getElementById('document-text-content');

  if (!docTextContent) {
    return;
  }

  const docHeader = document.getElementById('document-header');
  const docHeaderHtml =
    docHeader?.outerHTML || getDefaultHeaderHTML({ title: docTitle, author, publishedDate });

  const docTextContentHtml = docTextContent.outerHTML;
  // eslint-disable-next-line no-restricted-syntax
  const myWindow = window.open('', docTitle, 'height=400, width=600');

  if (!myWindow) {
    return;
  }

  const docNotesHtml = docNotes
    ? `
    <div class="notesContainer">
      <p>
        ${docNotes}
      </p>
    </div>
  `
    : '';

  myWindow.document.write(`
    <html>
      <head>
        <title>${docTitle}</title>

        <style>
          body {
            margin: 0 auto 56px;
            margin-bottom: 56px;
            max-width: calc(var(--reading-editable-line-length) + var(--content-gutter) * 2);
            margin-bottom: 115px;

            print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
          }

          #document-header {
            padding: 0 var(--content-gutter) 0;
          }

          ${highligterStyles}

          ${docTextContentStyles}

          .notesContainer {
            margin-top: 32px;
            margin-bottom: -16px;
            padding: 12px 16px;
            border: 1px solid var(--border-secondary);
            border-radius: 8px;
            margin: 20px var(--content-gutter) 0;
            font-family: var(--reading-editable-font-family);
          }

          .notesContainer p {
            color: var(--text-primary);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
          }
        </style>

        <script>
          // (function() {
          //   var afterPrint = function() {
          //     setTimeout(() => {
          //       window.close();
          //     }, 0);
          //   };

          //   if (window.matchMedia) {
          //       var mediaQueryList = window.matchMedia('print');
          //       mediaQueryList.addListener(function(mql) {
          //           if (!mql.matches) {
          //             afterPrint();
          //           }
          //       });
          //   }

          //   window.onafterprint = afterPrint;
          // }());
        </script>
      </head>

      <body>
          ${docHeaderHtml}
          ${docNotesHtml}
          ${docTextContentHtml}
      </body>
    </html>
  `);

  myWindow.document.close();
  myWindow.focus();
  myWindow.print();
};
