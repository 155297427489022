import { EventEmitter2 } from 'eventemitter2';
import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { globalState } from '../../../shared/foreground/models';
import type { HighlightElement } from '../../../shared/foreground/types';
import getClosestHTMLElement from '../../../shared/foreground/utils/getClosestHTMLElement';
import useHighlightResizeState from '../../../shared/foreground/utils/useHighlightResizeState';
import type { Highlight } from '../../../shared/types';
import { isExtension } from '../../../shared/utils/environment';
import { useIsLeftSidebarHidden, useIsRightSidebarHidden } from '../hooks/hooks';
import getElementRect from '../utils/getElementRect';
import getNumericCssPropertyValue from '../utils/getNumericCssPropertyValue';
import mergeRects from '../utils/mergeRects';
import AnnotationPopovers from './Popovers/AnnotationPopovers';
import { Props as PopoverProps } from './Popovers/Popover';
import SelectionAnnotationPopovers from './Popovers/SelectionAnnotationPopovers';

type IdToInfoMap = {
  [id: string]: {
    elements: HighlightElement[];
    hide: PopoverProps['hidePopover'];
    show: PopoverProps['showPopover'];
  };
};

type Props = {
  containerNodeSelector?: string;
  customClientRectGetter?: (references: PopoverProps['reference'][]) => DOMRect;
  customContentWidth?: number;
  doSidebarsExist?: boolean;
  eventEmitter: EventEmitter2;
  highlightElements: HighlightElement[];
  isAutoHighlightingAlwaysEnabled?: boolean;
  onHighlightElementClicked?(details: {
    highlightElementClassName: string;
    iconClicked?: 'note' | 'tag' | null;
    id: Highlight['id'];
    showAnnotationBarPopover(): void;
  }): void;
  onPositioningModeUpdated?(shouldShowInMargin: boolean): void;
  onShownIdsUpdated?(shownIds: Highlight['id'][]): void;
  renderPopover?: (
    props: {
      getBoundingClientRectForMargins: (references: PopoverProps['reference'][]) => DOMRect;
      highlightId?: Highlight['id'];
      references?: PopoverProps['reference'][];
      shouldShowInMargin?: boolean;
      isPdfTron?: boolean;
    } & Omit<PopoverProps, 'getBoundingClientRect' | 'reference'>,
  ) => JSX.Element;
  shouldAppendSelectionAnnotationPopovers?: boolean;
  isPdfTron?: boolean;
};

const defaultExport = React.memo(function HighlighterPopovers({
  containerNodeSelector = '#document-text-content',
  customClientRectGetter,
  customContentWidth,
  doSidebarsExist,
  eventEmitter,
  highlightElements,
  isAutoHighlightingAlwaysEnabled,
  onHighlightElementClicked,
  onShownIdsUpdated,
  isPdfTron,
  shouldAppendSelectionAnnotationPopovers:
    shouldAppendSelectionAnnotationPopoversArgument = !isAutoHighlightingAlwaysEnabled,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPositioningModeUpdated = () => {},
  renderPopover = ({ hidePopover, isShown, showPopover, ...props }) => (
    <AnnotationPopovers
      hideAnnotationBarPopover={hidePopover}
      isAnnotationBarPopoverShown={isShown}
      isAutoHighlightingAlwaysEnabled={isAutoHighlightingAlwaysEnabled}
      key={props.highlightId}
      showAnnotationBarPopover={showPopover}
      {...props}
    />
  ),
}: Props): JSX.Element {
  const [shownIds, setShownIds] = useState<Highlight['id'][]>([]);
  useEffect(() => onShownIdsUpdated?.(shownIds), [onShownIdsUpdated, shownIds]);
  const [popoverPositionUpdateCounter, setPopoverPositionUpdateCounter] = useState(0);

  const isLeftSidebarHidden = useIsLeftSidebarHidden();
  const isRightSidebarHidden = useIsRightSidebarHidden();
  const screenWidth = globalState(useCallback((state) => state.screenWidth, []));
  const { maxPopoverSize, sidebarWidth } = useMemo(
    () => ({
      maxPopoverSize: getNumericCssPropertyValue('--popover-max-width'),
      sidebarWidth: getNumericCssPropertyValue('--sidebars-max-width'),
    }),
    [],
  );

  const shouldShowInMargin = useMemo(() => {
    if (isExtension) {
      return false;
    }

    const numberOfSidebarsShown =
      doSidebarsExist === false
        ? 0
        : [isLeftSidebarHidden, isRightSidebarHidden].filter((isHidden) => !isHidden).length;
    const centerColumnWidth = screenWidth - numberOfSidebarsShown * sidebarWidth;
    const contentContainer = document.querySelector<HTMLElement>(containerNodeSelector);
    if (!contentContainer) {
      throw new Error("Can't find content container");
    }
    const contentWidth = customContentWidth ?? contentContainer.getBoundingClientRect().width;

    // Assumption: content is centered in middle column
    return (centerColumnWidth - contentWidth) / 2 > maxPopoverSize;
  }, [
    containerNodeSelector,
    doSidebarsExist,
    isLeftSidebarHidden,
    isRightSidebarHidden,
    maxPopoverSize,
    screenWidth,
    sidebarWidth,
    customContentWidth,
  ]);

  const highlightResizeState = useHighlightResizeState();
  const shouldAppendSelectionAnnotationPopovers = useMemo(() => {
    return shouldAppendSelectionAnnotationPopoversArgument && highlightResizeState.status === 'inactive';
  }, [highlightResizeState.status, shouldAppendSelectionAnnotationPopoversArgument]);

  useEffect(() => {
    if (highlightResizeState.status === 'inactive') {
      return;
    }
    setShownIds([]);
  }, [highlightResizeState.status]);

  useEffect(() => {
    onPositioningModeUpdated(shouldShowInMargin);
  }, [onPositioningModeUpdated, shouldShowInMargin]);

  const hideAnnotationBarPopover = useCallback((id: Highlight['id']) => {
    setShownIds((existingIds) => existingIds.filter((existingId) => existingId !== id));
  }, []);

  const showAnnotationBarPopover = useCallback((id: Highlight['id']) => {
    setShownIds((existingIds) => {
      if (existingIds.includes(id)) {
        return existingIds;
      }
      return [...existingIds, id];
    });
  }, []);

  const idToElementsMap = useMemo(
    () => groupBy(highlightElements, ({ dataset }) => dataset.highlightId),
    [highlightElements],
  );
  const idToInfoMap: IdToInfoMap = useMemo(() => {
    const result = {};

    for (const highlightElement of highlightElements) {
      const id = highlightElement.dataset.highlightId;
      if (!id) {
        // this can happen when a highlight is being removed
        continue;
      }
      if (!result[id]) {
        result[id] = {
          elements: [],
          hide: hideAnnotationBarPopover.bind(window, id),
          show: showAnnotationBarPopover.bind(window, id),
        };
      }
      result[id].elements.push(highlightElement);
    }

    return result;
  }, [hideAnnotationBarPopover, highlightElements, showAnnotationBarPopover]);

  const ids = useMemo(() => Object.keys(idToElementsMap), [idToElementsMap]);

  // Clean up shownIds when a highlight is removed
  useEffect(() => {
    setShownIds((existingIds) => {
      if (ids.length < existingIds.length) {
        return existingIds.filter((existingId) => ids.includes(existingId));
      }

      return existingIds;
    });
  }, [ids]);

  useEffect(() => {
    const handle = ({
      highlightElementClassName,
      iconClicked,
      id,
    }: {
      highlightElementClassName: string;
      iconClicked: 'note' | 'tag' | null;
      id: Highlight['id'];
    }) => {
      if (onHighlightElementClicked) {
        onHighlightElementClicked?.({
          highlightElementClassName,
          iconClicked,
          id,
          showAnnotationBarPopover: () => showAnnotationBarPopover(id),
        });
        return;
      }

      if (iconClicked) {
        if (iconClicked === 'note') {
          eventEmitter.emit(`annotationPopover-${id}:openHighlightNoteForm`, {});
          return;
        }
        if (iconClicked === 'tag') {
          eventEmitter.emit(`annotationPopover-${id}:openHighlightTagsForm`, {});
          return;
        }
      }

      showAnnotationBarPopover(id);
    };
    eventEmitter.on('content-frame:click', handle);
    eventEmitter.on('content-frame:created', handle);

    // Trigger popover positon update when the content has moved (e.g. images loaded)
    const onContentMoved = async () => {
      setPopoverPositionUpdateCounter((count) => count + 1);
    };
    eventEmitter.on('content-frame:content-moved', onContentMoved);

    return () => {
      eventEmitter.off('content-frame:click', handle);
      eventEmitter.off('content-frame:created', handle);
      eventEmitter.off('content-frame:content-moved', onContentMoved);
    };
  }, [eventEmitter, onHighlightElementClicked, showAnnotationBarPopover]);

  const getBoundingClientRectForMargins: (references: PopoverProps['reference'][]) => DOMRect =
    useCallback(
      (references) => {
        if (customClientRectGetter) {
          return customClientRectGetter(references);
        }

        /*
      What does all this do?
      First we get the smallest rectangle (A) which contains all highlight elements.
      Then we get the smallest rectangle (B) which contains all of the highlight
      elements' nearest block ancestor. We merge A and B, taking A's Y-axis
      attributes and B's X-axis attributes.
      This is so we can place the tooltip such that it's top-aligned with the first
      highlight element but to the right of the paragraph (for example).
    */
        const highlightsRect = mergeRects(references.map(getElementRect));
        const blockElements = uniq(
          references
            .map((element) =>
              getClosestHTMLElement(element, (htmlElement) => {
                const { display } = window.getComputedStyle(htmlElement);
                return display === 'block' || display.startsWith('table');
              }),
            )
            .filter(Boolean),
        );

        const xAxisAttributes = blockElements.length
          ? pick(mergeRects(blockElements.map(getElementRect)), ['left', 'right', 'x', 'width'])
          : {};

        return {
          // Take X-axis attributes from block ancestor
          ...xAxisAttributes,
          // Take Y-axis attributes from highlight elements rect
          ...pick(highlightsRect, ['bottom', 'height', 'top', 'y']),
        } as DOMRect;
      },
      [customClientRectGetter],
    );

  const popovers = useMemo(() => {
    const results = Object.entries(idToInfoMap)
      .map(([id, { elements, hide, show }]) =>
        renderPopover({
          getBoundingClientRectForMargins,
          hidePopover: hide,
          highlightId: id,
          isShown: shownIds.includes(id),
          positionUpdateCounter: popoverPositionUpdateCounter,
          references: elements,
          shouldShowInMargin,
          showPopover: show,
          isPdfTron,
        }),
      )
      // Why? The document-share app doesn't render a popover if there's no note for example
      .filter(Boolean);

    if (shouldAppendSelectionAnnotationPopovers) {
      results.push(
        <SelectionAnnotationPopovers
          containerNodeSelector={containerNodeSelector}
          key="selection-annotation-popovers"
        />,
      );
    }

    return results;
  }, [
    containerNodeSelector,
    getBoundingClientRectForMargins,
    idToInfoMap,
    popoverPositionUpdateCounter,
    renderPopover,
    shouldAppendSelectionAnnotationPopovers,
    shouldShowInMargin,
    shownIds,
    isPdfTron,
  ]);

  return <>{popovers}</>;
});

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
